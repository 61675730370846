// Here you can add other styles

/* width */
::-webkit-scrollbar {
  // display: none;
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.sidebar ::-webkit-scrollbar {
  display: none;
}

.settingButton {
  list-style: none;
}
