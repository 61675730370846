/* Provide sufficient contrast against white background */

a {
  color: #002c7c;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #002c7c;
  border-color: #002c7c;
}
